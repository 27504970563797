









































import { ref, Ref, computed, watch } from '@vue/composition-api';

import { breakpoints } from '@/utils';

import GeneralId from './views/id/GeneralID.vue';
import StudentId from './views/id/StudentID.vue';
import SchoolId from './views/id/SchoolID.vue';
import ParentId from './views/id/ParentID.vue';
import TeacherId from './views/id/TeacherID.vue';
import EmployerId from './views/id/EmployerID.vue';
import OrganizerId from './views/id/OrganizerID.vue';

export default {
  name: 'SetupPortfolio',
  components: {
    GeneralId,
    EmployerId,
    StudentId,
    TeacherId,
    SchoolId,
    ParentId,
    OrganizerId
  },

  props: {
    userTypeList: {
      type: Array,
      default: () => []
    }
  },

  setup(_props, ctx) {
    const step = ref(0);
    const selectedTypes: Ref<string[]> = ref([]);
    const idSections = computed(() => selectedTypes.value.map(type => `${type.toLowerCase()}-id`));
    const computedClasses = computed(() =>
      (breakpoints[ctx.root.$vuetify.breakpoint.name] as unknown as string) >
      (breakpoints.md as unknown as string)
        ? ['profile__container', 'pc-container']
        : []
    );

    function changeStep(val) {
      if (step.value < selectedTypes.value.length) {
        step.value++;
      }

      if (val === selectedTypes.value[selectedTypes.value.length - 1]) {
        ctx.emit('closeModal');
      }
    }

    if (_props.userTypeList.length > 0) {
      selectedTypes.value = _props.userTypeList as any;
      step.value++;
    }

    watch(step, step => {
      if (step > selectedTypes.value.length) ctx.root.$router.go(0);
      // OLD FUNCTION THAT DIDNT REFRESH AFTER PORTFOLIO SETUP WHICH REMOVED THE NAVBAR WHEN PUSHED TO PORTFOLIO
      // if (step > selectedTypes.value.length) ctx.root.$router.push({ name: 'portfolio' });
    });
    return { step, selectedTypes, idSections, computedClasses, changeStep };
  }
};
